import React from 'react'
import { useTranslation } from 'react-i18next'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

const AuthHeader = ({ loggedIn, error, loading, authLogout }) => {
  const { t } = useTranslation('users')

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">{t('Learning Marker')}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <div style={{ flex: 1 }} />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {!loggedIn && (
            <>
              <Nav.Link href={'/login'}>{t('Login')}</Nav.Link>
              <Nav.Link href={'/register'}>{t('Register')}</Nav.Link>
            </>
          )}
          {!!loggedIn && (
            <>
              <Nav.Link href="/customers">{t('Customers')}</Nav.Link>
              <Nav.Link href="/users">{t('Users')}</Nav.Link>

              <NavDropdown title="Account" id="basic-nav-dropdown">
                <NavDropdown.Item href="/profile">{t('Profile')}</NavDropdown.Item>
                <NavDropdown.Item href="/changePassword">{t('Change Password')}</NavDropdown.Item>
                <NavDropdown.Item href="/resetPassword">{t('Reset Password')}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={authLogout}>{t('Logout')}</NavDropdown.Item>
              </NavDropdown>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default AuthHeader
