import { connect } from 'react-redux'
import {
  fetchSchoologyUser,
  selectSchoologyUserError,
  selectSchoologyUserLoading,
  selectSchoologyUserId,
  disconnectSchoology,
} from 'store/schoology/userInfo'
import SchoologyConnection from './SchoologyConnection'

const mapStateToProps = state => ({
  loading: selectSchoologyUserLoading(state),
  error: selectSchoologyUserError(state),
  userId: selectSchoologyUserId(state),
})

const mapDispatchToProps = {
  fetchSchoologyUser,
  disconnectSchoology,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoologyConnection)
