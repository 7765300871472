import { useQuery } from 'react-query'
import api from 'clients/lmClient'
import { AssignmentPdf } from 'types'

export const useAssignments = () =>
  useQuery<AssignmentPdf[], Error>('assignments', async () => {
    const response = await api().Assignments.list()

    const data = response.data()

    return data as AssignmentPdf[]
  })
