import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import FormField from '../../../ui/FormField'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import createClient from 'clients/authClient'

const EnterEmail = ({ onComplete }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <h2>Request Password Reset Email</h2>

      {error && <Alert variant="danger">There was a problem requesting this reset code.</Alert>}

      <Formik
        initialValues={{ email: '' }}
        validate={values => {
          const errors = {}

          if (!values.email) {
            errors.email = 'Required'
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
          }

          return errors
        }}
        onSubmit={values => {
          setLoading(true)
          createClient()
            .Auth.resetPasswordRequest({ body: values })
            .then(() => {
              onComplete(values)
            })
            .catch(setError)
            .finally(() => setLoading(false))
        }}
      >
        {() => (
          <Form>
            <FormField name="email" inputType="email" label="Email" />

            <Button as="input" type="submit" value="Submit" disabled={loading} block />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EnterEmail
