import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { WarningAmber } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import CheckButton from '../CheckButton'

type Props = {
  submissions: any
  fetchSubmissions(): void
}

export const Submissions = ({ submissions, fetchSubmissions }: Props) => {
  const { t } = useTranslation('instructor')

  const columns: GridColDef[] = [
    { field: 'name_first', headerName: 'First Name', minWidth: 150 },
    { field: 'name_last', headerName: 'Last Name', minWidth: 150 },
    {
      field: 'download',
      headerName: 'Download',
      minWidth: 150,
      valueGetter: (params: any) => params.row.latestDownload?.created_at,
      renderCell: (params) => {
        if (!params.value) {
          return '-'
        }
        const downloadDayJs = dayjs(params.value)
        return <Typography variant="caption">{downloadDayJs.format('dd MMM DD h:mma')}</Typography>
      },
    },
    {
      field: 'submission',
      headerName: 'Submission',
      minWidth: 200,
      flex: 1,
      valueGetter: (params: any) => params.row.latestSubmission?.date,
      renderCell: (params) => {
        if (!params.value) {
          return '-'
        }
        const submissionDayJs = dayjs(params.value)

        const { latestDownload } = params.row

        return (
          <Typography variant="caption">
            {!latestDownload && (
              <Stack direction="row" alignItems="center">
                <WarningAmber fontSize="small" />
                &nbsp;{t('noDownload')}
              </Stack>
            )}
            <div>{submissionDayJs.format('dd MMM DD h:mma')}</div>
          </Typography>
        )
      },
    },
    {
      field: 'checkResult',
      sortable: false,
      headerName: 'Learning Marker',
      flex: 1,
      renderCell: (params) => {
        const { latestDownload, latestSubmission } = params.row
        if (latestSubmission) {
          return (
            <CheckButton
              key={latestSubmission.uid}
              download={latestDownload}
              {...latestSubmission}
              onNewResult={fetchSubmissions}
              checkReport={latestSubmission.checkReport}
            />
          )
        }
      },
    },
  ]

  return (
    <Box style={{ height: 450, width: '100%' }}>
      {!!submissions && (
        <DataGrid
          columns={columns}
          rows={submissions}
          getRowId={(params) => params.uid}
          density="compact"
        />
      )}
    </Box>
  )
}
