import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ArrowForward, Settings } from '@mui/icons-material'
import { Alert, Container } from '@mui/material'

const NeedSchoologyConnection = () => {
  const { t } = useTranslation('instructor')

  return (
    <Container>
      <Alert severity="error">
        <div>{t('connectionRequired')}</div>

        <Link to="/settings">
          <Settings /> {t('goToSettings')} <ArrowForward />
        </Link>
      </Alert>
    </Container>
  )
}

export default NeedSchoologyConnection
