import React from 'react'
import { connect } from 'react-redux'
import { authLogout } from 'store/auth/actions'
import { selectAuthError, selectAuthPending, selectAuthLoggedIn } from 'store/auth/reducer'
import AuthHeader from './AuthHeader'

const mapStateToProps = (state) => ({
  loading: selectAuthPending(state),
  error: selectAuthError(state),
  loggedIn: selectAuthLoggedIn(state),
})

const mapDispatchToProps = {
  authLogout,
}

const AuthHeaderContainer = ({ loggedIn, loading, error, authLogout }) => {
  return <AuthHeader loading={loading} error={error} loggedIn={loggedIn} authLogout={authLogout} />
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthHeaderContainer)
