import forge from 'mappersmith'
import AccessTokenMiddleware from './middleware/accessToken'

const createLmClient = (state) =>
  forge({
    clientId: 'lm-lti',
    host: `${process.env.REACT_APP_LM_BASE_URL || ''}/api/v1`,
    middleware: [AccessTokenMiddleware(state)],
    resources: {
      Customer: {
        create: { method: 'post', path: '/customer' },
        list: { method: 'get', path: '/customer' },
        byId: { method: 'get', path: '/customer/{customerId}' },
        update: { method: 'put', path: '/customer/{customerId}' },
        delete: { method: 'delete', path: '/customer/{customerId}' },
        generateKeys: { method: 'post', path: '/customer/{customerId}/generateKeys' },
      },
      Assignments: {
        upload: { method: 'post', path: '/assignments' },
        list: { path: '/assignments' },
        request: { path: '/assignments/{assignmentId}/request' },
        download: { path: '/assignments/{assignmentId}/request/{jobId}/download' },
        retrieve: { path: '/assignments/{assignmentId}' },
        retrieveMeta: { path: '/assignments/{assignmentId}/meta' },
        delete: { method: 'delete', path: '/assignments/{assignmentId}' },
      },
      Schoology: {
        testKey: { method: 'post', path: '/schoology/apiKey/test' },
        userInfo: { path: '/schoology/userInfo' },
        disconnect: { path: '/schoology/disconnect' },
        submissions: { path: '/schoology/submissions' },
        checkSubmission: { path: '/schoology/checkSubmission' },
        checkLearningMark: { path: '/schoology/checkLearningMark' },
        ltiContext: { path: '/ltiContext' },
      },
      Session: {
        roles: { path: '/roles' },
      },
      Jobs: {
        status: { path: '/job/{jobId}' },
      },
    },
  })

export default createLmClient
