import React from 'react'
import { useTranslation } from 'react-i18next'
import DownloadLink from './DownloadLink'
import { CircularProgress } from '@mui/material'
import { useRequestMarkedAssignment } from 'queries/useRequestMarkedAssignment'
import { useAssignments } from 'queries/useAssignments'

const Learner = ({}) => {
  const { t } = useTranslation('learner')

  const { data: assignments, isLoading: assignmentsLoading } = useAssignments()

  const { mutateAsync: requestAssignment, isLoading: downloadRequested } =
    useRequestMarkedAssignment()

  const assignmentFile = assignments && assignments[0]

  if (assignmentsLoading) {
    return (
      <CircularProgress>
        <span className="sr-only">{t('Loading...')}</span>
      </CircularProgress>
    )
  }

  if (!assignmentFile) {
    return <em>{t('empty')}</em>
  }

  return (
    <div style={{ padding: 20 }}>
      <div>
        <DownloadLink
          loading={downloadRequested}
          filename={assignmentFile.filename}
          onClick={() => requestAssignment(assignmentFile.id)}
          created_at={assignmentFile.created_at}
        />
      </div>
    </div>
  )
}

export default Learner
