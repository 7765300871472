import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Divider, IconButton, Stack, Typography } from '@mui/material'
import api from 'clients/lmClient'
import { Delete, Settings } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Link } from 'react-router-dom'
import { useSubmissions } from '../../queries/useSubmissions'
import { useAssignments } from '../../queries/useAssignments'
import { useDeleteAssignment } from '../../queries/useDeleteAssignment'
import { Submissions } from './Submissions'

const Instructor = ({}) => {
  const { t } = useTranslation('instructor')
  const [uploading, setUploading] = useState(false)

  const {
    data: assignments,
    isLoading: assignmentsLoading,
    refetch: fetchAssignments,
  } = useAssignments()
  const { mutateAsync: deleteAssignment, isLoading: isDeleting } = useDeleteAssignment()

  const { data: submissions, refetch: fetchSubmissions } = useSubmissions()

  const upload = () => {
    setUploading(true)
    const selectedFile = document.getElementById('assignmentFile').files[0]
    const formData = new FormData()
    formData.append('assignment', selectedFile)

    api()
      .Assignments.upload({ body: formData })
      .then(fetchAssignments)
      .finally(() => setUploading(false))
  }

  const assignmentFile = assignments && assignments[0]

  const handleDelete = () => deleteAssignment(assignmentFile.id).then(() => fetchAssignments())

  return (
    <>
      <Link to="/settings" style={{ position: 'absolute', top: 5, right: 10 }}>
        <Settings />
      </Link>

      <Box sx={{ width: '100%', padding: 2 }}>
        <Stack>
          <Stack direction="row" spacing={2}>
            <Typography variant="h6">{t('assignment')}</Typography>

            {assignmentsLoading ? (
              <Stack direction="row">
                <CircularProgress
                  size={12}
                  sx={{
                    color: '#fff',
                  }}
                  thickness={2}
                />
                <span className="sr-only">{t('loading')}</span>
              </Stack>
            ) : assignmentFile ? (
              <Stack direction="row" alignItems="center">
                <Typography>{assignmentFile.filename}</Typography>

                <IconButton disabled={isDeleting} onClick={handleDelete}>
                  <Delete />
                </IconButton>
              </Stack>
            ) : (
              <>
                <Stack>
                  <div>
                    <em>{t('Upload an assignment PDF')}</em>
                  </div>

                  <input
                    type="file"
                    id="assignmentFile"
                    accept="application/pdf"
                    disabled={uploading}
                  />
                </Stack>

                <LoadingButton variant="contained" onClick={upload} loading={uploading}>
                  {t('upload')}
                </LoadingButton>
              </>
            )}
          </Stack>

          {assignmentFile && assignmentFile.status === 'created' && <p>{t('assignmentIsReady')}</p>}

          {assignmentFile && (
            <>
              <Divider sx={{ margin: 2 }} />

              <Typography variant="h6">{t('submissions')}</Typography>

              <Submissions submissions={submissions} fetchSubmissions={fetchSubmissions} />
            </>
          )}
        </Stack>
      </Box>
    </>
  )
}

export default Instructor
