import { connect } from 'react-redux'
import { customerFetchAll, customerDelete } from 'store/customers/actions'
import { AppState } from 'store'
import {
  selectCustomersLoading,
  selectCustomersError,
  selectCustomers,
} from 'store/customers/reducer'
import Customers from './Customers'

const mapStateToProps = (state: AppState) => ({
  loading: selectCustomersLoading(state),
  error: selectCustomersError(state),
  customers: selectCustomers(state),
})

const mapDispatchToProps = { customerFetchAll, customerDelete }

export default connect(mapStateToProps, mapDispatchToProps)(Customers)
