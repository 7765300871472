import React from 'react'
import { connect } from 'react-redux'
import { selectAuthLoggedIn } from 'store/auth/reducer'
import Admin from './Admin'

const mapStateToProps = (state) => ({
  loggedIn: selectAuthLoggedIn(state),
})

const AdminContainer = ({ loggedIn }) => {
  return <Admin loggedIn={loggedIn} />
}

export default connect(mapStateToProps)(AdminContainer)
