import ReactGA from 'react-ga'

export const logger = store => next => action => {
  const result = next(action)

  try {
    const { type } = result
    const gaCategory = type.split('_')[0]
    const gaAction = type.replace(`${gaCategory}_`, '')

    ReactGA.event({ category: gaCategory, action: gaAction })
  } catch (err) {
    // action was probably a thunk
  }

  return result
}

export const crashReporter = store => next => action => {
  try {
    return next(action)
  } catch (err) {
    ReactGA.exception({
      description: err,
    })

    throw err
  }
}
