import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Stack } from '@mui/material'
import SchoologyConnection from '../SchoologyConnection'
import { Link } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

const SettingsView = () => {
  const { t } = useTranslation('settings')

  return (
    <Container>
      <Stack spacing={1}>
        <Stack direction="row">
          <Link to="/lti">
            <ArrowBack /> {t('back')}
          </Link>
        </Stack>

        <SchoologyConnection />
      </Stack>
    </Container>
  )
}

export default SettingsView
