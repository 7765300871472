import React from 'react'
import { connect } from 'react-redux'
import { authChangePassword } from 'store/auth/actions'
import { selectAuthError, selectAuthPending } from 'store/auth/reducer'
import ChangePassword from './ChangePassword'

const mapStateToProps = state => ({
  loading: selectAuthPending(state),
  error: selectAuthError(state),
})

const mapDispatchToProps = { authChangePassword }

const ChangePasswordContainer = props => {
  return <ChangePassword {...props} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer)
