import React from 'react'
import { Close, Check } from '@mui/icons-material'

const style = { fontSize: 26, fontWeight: 'bold', marginLeft: 5, marginRight: 5 }

const CheckMark = ({ value }: { value?: boolean }) => {
  if (value === undefined) {
    return <span style={{ ...style, color: 'gray' }}>?</span>
  }

  if (value) {
    return <Check sx={{ color: 'green' }} />
  }

  return <Close sx={{ color: 'red' }} />
}

export default CheckMark
