import React, { useState, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import DownloadLink from '../Learner/DownloadLink'
import api from 'clients/lmClient'
import { connect } from 'react-redux'
import { fetchSchoologyUser, selectSchoologyUserId } from 'store/schoology/userInfo'
import { CircularProgress, Stack } from '@mui/material'
import { useRequestMarkedAssignment } from 'queries/useRequestMarkedAssignment'

const mapStateToProps = (state) => ({
  schoologyUserId: selectSchoologyUserId(state),
})

const mapDispatchToProps = { fetchSchoologyUser }

const DownloadAssignment = ({ fetchSchoologyUser, schoologyUserId }) => {
  const { t } = useTranslation('downloadAssignment')
  const { assignmentPdfId, customerId } = useParams()
  const [errors, setErrors] = useState([])

  const { mutateAsync: requestAssignment, isLoading: downloadRequested } =
    useRequestMarkedAssignment()

  const connectUrl = useMemo(
    () =>
      [
        process.env.REACT_APP_LM_BASE_URL,
        `/api/v1/schoology/oauth_connect_standalone/`,
        customerId,
        `?redirectPath=/download/${customerId}/${assignmentPdfId}`,
      ].join(''),
    [assignmentPdfId, customerId]
  )

  useEffect(() => {
    fetchSchoologyUser()

    window.onerror = (err) => setErrors([...errors, err])
  }, [])

  const { data: assignment } = useQuery(
    ['assignmentPdf', assignmentPdfId],
    () =>
      api()
        .Assignments.retrieveMeta({ assignmentId: assignmentPdfId })
        .then((response) => response.data()),
    {
      enabled: !!assignmentPdfId,
    }
  )

  const handleClick = () => {
    if (schoologyUserId) {
      requestAssignment(assignmentPdfId)
    } else {
      window.location.replace(connectUrl)
    }
  }

  console.log({ schoologyUserId })

  if (!schoologyUserId) {
    return (
      <div>
        <p>{t('pleaseConnect')}</p>
        <a target="_blank" href={connectUrl}>
          {t('connect')}
        </a>
      </div>
    )
  }

  return (
    <>
      {!downloadRequested ? (
        <DownloadLink filename={assignment && assignment.filename} onClick={handleClick} hideDate />
      ) : (
        <Stack direction="row">
          <CircularProgress />
          <span className="sr-only">{t('loading')}</span>
        </Stack>
      )}
      <pre>{errors.map((error) => JSON.stringify(error, null, 2))}</pre>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAssignment)
