import React from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'

const Users = ({ users, error, loading }) => {
  const { t } = useTranslation('users')

  return (
    <div>
      <h1>Users</h1>
      {error && <span style={{ testStyle: 'italic', color: 'red' }}>{error.message}</span>}
      {loading && <Spinner animation="border" role="status" />}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('username')}</th>
            <th>{t('created')}</th>
          </tr>
        </thead>

        <tbody>
          {users &&
            users.map(({ id, username, created_at }) => (
              <tr key={id}>
                <td>{username}</td>
                <td>{created_at}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Users
