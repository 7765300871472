import actionTypeMap from 'utils/actionTypeMap'

export default actionTypeMap(
  [
    'USER_INFO_FETCH',
    'USER_INFO_ERROR',
    'USER_INFO_SUCCESS',
    'DISCONNECT',
    'DISCONNECT_ERROR',
    'DISCONNECT_SUCCESS',
  ],
  'schoology'
)
