import { selectAuthJwt } from 'store/auth/reducer'

const accessTokenMiddleware = (state) => {
  const jwt = state && selectAuthJwt(state)

  return () => ({
    request(request) {
      return request.enhance({
        headers: { Authorization: `jwt ${jwt}` },
      })
    },
  })
}

export default accessTokenMiddleware
