import React, { useEffect, useState } from 'react'
import api from 'clients/lmClient'
import Instructor from './Instructor'
import Learner from './Learner'
import AdminApp from './Admin'
import { Route, Switch } from 'react-router-dom'
import SettingsView from './SettingsView'
import DownloadAssignment from './DownloadAssignment'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const App = () => {
  const [roles, setRoles] = useState(null)

  useEffect(() => {
    try {
      api()
        .Session.roles()
        .then((response) => setRoles(response.data()))
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <Switch>
      <Route path="/lti">
        {!!roles && roles.includes('Instructor') && <Instructor />}
        {!!roles && roles.includes('Learner') && <Learner />}
      </Route>

      <Route path="/download/:customerId/:assignmentPdfId">
        <DownloadAssignment />
      </Route>

      <Route path="/settings">
        <SettingsView />
      </Route>

      <AdminApp />
    </Switch>
  )
}

export default App
