import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckMark from './CheckMark'
import { WarningAmber } from '@mui/icons-material'
import { Stack } from '@mui/material'

type Props = {
  mark: string
  checkReport: any
}

const CheckResult = ({ mark, checkReport }: Props) => {
  const { t } = useTranslation('checkResult')

  return checkReport.unchangedWarning ? (
    <Stack direction="row" alignItems="center">
      <WarningAmber fontSize="small" />
      &nbsp;{t('unchanged')}
    </Stack>
  ) : (
    <Stack direction="row" alignItems="center">
      <CheckMark value={checkReport.status === 'match'} />

      {checkReport.status === 'wrong_match' && (
        <span style={{ color: 'red' }}>
          {t('compare', { count: checkReport.matches?.length })}: {checkReport.matches?.join(', ')}
        </span>
      )}

      {checkReport.originalPages !== checkReport.testPages && (
        <span style={{ color: 'red', fontWeight: 'bold', fontStyle: 'italic' }}>
          {t('pageCount', { count: checkReport.testPages })}
        </span>
      )}
    </Stack>
  )
}

export default CheckResult
