import React from 'react'
import ReactDOM from 'react-dom'
import './i18n/index'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import store, { persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import ReactGA from 'react-ga'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {},
  },
})

ReactGA.initialize('UA-174206173-1')
ReactGA.pageview('/')

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
