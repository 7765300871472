import {
  ADMIN_FETCH_USERS,
  ADMIN_FETCH_USERS_ERROR,
  ADMIN_FETCH_USERS_SUCCESS,
  ADMIN_CLEAR_USERS,
} from './actionTypes'

const initialState = {
  pendingAction: undefined,
  users: null,
}

const reducer = function(state = initialState, action) {
  switch (action.type) {
    case ADMIN_FETCH_USERS:
      return { ...state, error: undefined, pendingAction: ADMIN_FETCH_USERS }

    case ADMIN_FETCH_USERS_ERROR:
      return { ...state, error: action.payload, pendingAction: undefined }

    case ADMIN_FETCH_USERS_SUCCESS:
      return { ...state, users: action.payload.users, error: undefined, pendingAction: undefined }

    case ADMIN_CLEAR_USERS:
      return { ...initialState, users: null }

    default:
      return state
  }
}

export default reducer

const selectBase = state => state.admin

export const selectAdminPending = state => selectBase(state).pendingAction
export const selectAdminError = state => selectBase(state).error
export const selectAdminUsers = state => selectBase(state).users
