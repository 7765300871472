import React from 'react'
import { Formik, Form } from 'formik'
import FormField from '../../ui/FormField'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Nav from 'react-bootstrap/Nav'

const Login = ({ authLogin, error, loading }) => (
  <>
    <h2>Login</h2>

    {error && <Alert variant="danger">{error.message}</Alert>}

    <Formik
      initialValues={{ username: '', password: '' }}
      validate={values => {
        const errors = {}
        if (!values.username) {
          errors.username = 'Required'
        } else if (!/^[A-Z0-9._%+-]+$/i.test(values.username)) {
          errors.username = 'Invalid username'
        }
        return errors
      }}
      onSubmit={({ username, password }) => {
        authLogin(username, password)
      }}
    >
      {() => (
        <Form>
          <FormField name="username" inputType="username" label="Username" />

          <FormField name="password" inputType="password" label="Password" />

          <Button as="input" type="submit" value="Submit" disabled={loading} block />
        </Form>
      )}
    </Formik>

    <Nav.Link href={'/resetPassword'}>I forgot my password.</Nav.Link>
  </>
)

export default Login
