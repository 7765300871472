import {
  CUSTOMER_CREATE,
  CUSTOMER_CREATE_ERROR,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_FETCH_ALL,
  CUSTOMER_FETCH_ALL_ERROR,
  CUSTOMER_FETCH_ALL_SUCCESS,
  CUSTOMER_FETCH_ONE,
  CUSTOMER_FETCH_ONE_ERROR,
  CUSTOMER_FETCH_ONE_SUCCESS,
  CUSTOMER_DELETE,
  CUSTOMER_DELETE_ERROR,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_UPDATE_SUCCESS,
} from './actionTypes'

const initialState = {
  loading: false,
  customers: [],
}

const customersReducer = function(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_CREATE:
      return { ...state, loading: true, error: undefined }
    case CUSTOMER_CREATE_ERROR:
      return { ...state, loading: false, error: action.payload }
    case CUSTOMER_CREATE_SUCCESS:
      return { ...state, loading: false, error: undefined }

    case CUSTOMER_FETCH_ALL:
      return { ...state, loading: true, error: undefined }
    case CUSTOMER_FETCH_ALL_ERROR:
      return { ...state, loading: false, error: action.payload }
    case CUSTOMER_FETCH_ALL_SUCCESS:
      return { ...state, loading: false, error: undefined, customers: action.payload.customers }

    case CUSTOMER_FETCH_ONE:
      return { ...state, loading: true, error: undefined }
    case CUSTOMER_FETCH_ONE_ERROR:
      return { ...state, loading: false, error: action.payload }
    case CUSTOMER_FETCH_ONE_SUCCESS: {
      const { customer } = action.payload
      const otherCustomers =
        state.customers && state.customers.filter(({ id }) => id !== customer.id)
      return {
        ...state,
        loading: false,
        error: undefined,
        customers: [customer, ...otherCustomers],
      }
    }

    case CUSTOMER_DELETE:
      return { ...state, loading: true, error: undefined }
    case CUSTOMER_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload }
    case CUSTOMER_DELETE_SUCCESS:
      const otherCustomers = state.customers.filter(({ id }) => id !== action.payload.customerId)
      return {
        ...state,
        loading: false,
        error: undefined,
        customers: otherCustomers,
      }

    case CUSTOMER_UPDATE:
      return { ...state, loading: true, error: undefined }
    case CUSTOMER_UPDATE_ERROR:
      return { ...state, loading: false, error: action.payload }
    case CUSTOMER_UPDATE_SUCCESS: {
      const { customer } = action.payload
      const otherCustomers = state.customers.filter(({ id }) => id !== customer.id)
      return {
        ...state,
        loading: false,
        error: undefined,
        customers: [customer, ...otherCustomers],
      }
    }

    default:
      return state
  }
}

export default customersReducer

const selectBase = state => state.customers

export const selectCustomersLoading = state => selectBase(state).loading
export const selectCustomersError = state => selectBase(state).error
export const selectCustomers = state => selectBase(state).customers
