import forge from 'mappersmith'
import AccessTokenMiddleware from './middleware/accessToken'

const createApi = state => {
  return forge({
    clientId: 'lm-auth',
    host: `${process.env.REACT_APP_LM_BASE_URL || ''}/api/v1/auth`,
    middleware: [AccessTokenMiddleware(state)],
    resources: {
      Auth: {
        register: { method: 'post', path: '/register' },
        login: { method: 'post', path: '/login' },
        logout: { method: 'post', path: '/logout' },
        changePassword: { method: 'post', path: '/changePassword' },
        resetPasswordRequest: { method: 'post', path: '/resetPassword/requestCode' },
        resetPasswordCheckCode: { method: 'post', path: '/resetPassword/checkCode' },
        resetPassword: { method: 'post', path: '/resetPassword' },
      },
      Admin: {
        users: { path: '/users' },
      },
    },
  })
}

export default createApi
