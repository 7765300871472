export const CUSTOMER_FETCH_ONE = 'CUSTOMER_FETCH_ONE'
export const CUSTOMER_FETCH_ONE_ERROR = 'CUSTOMER_FETCH_ONE_ERROR'
export const CUSTOMER_FETCH_ONE_SUCCESS = 'CUSTOMER_FETCH_ONE_SUCCESS'
export const CUSTOMER_FETCH_ALL = 'CUSTOMER_FETCH_ALL'
export const CUSTOMER_FETCH_ALL_ERROR = 'CUSTOMER_FETCH_ALL_ERROR'
export const CUSTOMER_FETCH_ALL_SUCCESS = 'CUSTOMER_FETCH_ALL_SUCCESS'
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE'
export const CUSTOMER_DELETE_ERROR = 'CUSTOMER_DELETE_ERROR'
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS'
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE'
export const CUSTOMER_UPDATE_ERROR = 'CUSTOMER_UPDATE_ERROR'
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS'
export const CUSTOMER_CREATE = 'CUSTOMER_CREATE'
export const CUSTOMER_CREATE_ERROR = 'CUSTOMER_CREATE_ERROR'
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS'
