export enum CustomerStatus {
  ENABLED = 'enabled',
}

export type CustomerValues = {
  contact_name: string
  contact_email: string
  status?: CustomerStatus
  district_url: string
  schoology_consumer_key?: string
  schoology_consumer_secret?: string
}

export interface Customer extends CustomerValues {
  id: string
  lti_consumer_key: string
  lti_shared_secret: string
  created_at: string
  updated_at: string
}
