import React, { useState } from 'react'

import EnterEmail from './steps/EnterEmail'
import EnterCode from './steps/EnterCode'
import EnterNewPassword from './steps/EnterNewPassword'
import Success from './steps/Success'

const Steps = [
  { id: 'enterEmail', stepComponent: EnterEmail },
  { id: 'enterCode', stepComponent: EnterCode },
  { id: 'enterNewPassword', stepComponent: EnterNewPassword },
  { id: 'success', stepComponent: Success },
]

const ResetPassword = () => {
  const [step, setStep] = useState(0)
  const [state, setState] = useState({})

  const advance = () => {
    setStep(step + 1)
  }

  const StepComponent = Steps[step].stepComponent

  return (
    <StepComponent
      state={state}
      onComplete={update => {
        setState({ ...state, ...update })
        advance()
      }}
    />
  )
}

export default ResetPassword
