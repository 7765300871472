import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Nav from 'react-bootstrap/Nav'

const Success = () => (
  <>
    <Alert variant="success">Success! Your password has been updated.</Alert>

    <Nav.Link href={'/login'}>Login</Nav.Link>
  </>
)

export default Success
