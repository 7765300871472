import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import FormField from '../../../ui/FormField'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import createClient from 'clients/authClient'

const EnterCode = ({ state, onComplete }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <h2>Enter Password Reset Code</h2>

      {error && <Alert variant="danger">There was a problem verifying this reset code.</Alert>}

      <Formik
        initialValues={{ code: '' }}
        validate={values => {
          const errors = {}

          if (!values.code) {
            errors.code = 'Required'
          }

          return errors
        }}
        onSubmit={values => {
          setLoading(true)
          createClient()
            .Auth.resetPasswordCheckCode({ body: { ...state, ...values } })
            .then(() => onComplete(values))
            .catch(setError)
            .finally(() => setLoading(false))
        }}
      >
        {() => (
          <Form>
            <FormField name="code" inputType="text" label="Code" />

            <Button as="input" type="submit" value="Submit" disabled={loading} block />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EnterCode
