import createClient from 'clients/authClient'

import {
  ADMIN_FETCH_USERS,
  ADMIN_FETCH_USERS_SUCCESS,
  ADMIN_FETCH_USERS_ERROR,
  ADMIN_CLEAR_USERS,
} from './actionTypes'

export const adminFetchUsers = () => async (dispatch, getState) => {
  dispatch({ type: ADMIN_FETCH_USERS })

  createClient(getState())
    .Admin.users()
    .then(response => {
      dispatch({ type: ADMIN_FETCH_USERS_SUCCESS, payload: { users: response.data() } })
    })
    .catch(response => {
      let error = new Error('network error')
      try {
        error = response.data()
      } catch (err) {
        console.log(response)
      }
      dispatch({ type: ADMIN_FETCH_USERS_ERROR, error: true, payload: error })
    })
}

export const adminClearUsers = () => ({ type: ADMIN_CLEAR_USERS })
