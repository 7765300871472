import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import lmClient from 'clients/lmClient'
import { Customer } from 'types'
import { CUSTOMER_UPDATE, CUSTOMER_UPDATE_ERROR, CUSTOMER_UPDATE_SUCCESS } from './actionTypes'

export const customerUpdate = (customerId: string, updates: Partial<Customer>) => async (
  dispatch: ThunkDispatch<any, null, Action<string>>
) => {
  dispatch({ type: CUSTOMER_UPDATE })

  try {
    const response = await lmClient().Customer.update({ customerId, body: updates })

    dispatch({ type: CUSTOMER_UPDATE_SUCCESS, payload: { customerId, customer: response.data() } })
  } catch (error) {
    dispatch({ type: CUSTOMER_UPDATE_ERROR, error: true, payload: error })
  }
}
