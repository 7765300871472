import { useQuery } from 'react-query'
import api from 'clients/lmClient'
import { Submission } from 'types'

export const useSubmissions = () =>
  useQuery<Submission[]>('submissions', async () => {
    const response = await api().Schoology.submissions()

    const data = response.data()

    return data as Submission[]
  })
