import React from 'react'
import { connect } from 'react-redux'
import Wizard from './Wizard'
import { Redirect } from 'react-router-dom'
import { selectAuthState } from 'store/auth/reducer'

const mapStateToProps = state => ({
  authState: selectAuthState(state),
})

const ResetPasswordContainer = props => {
  if (props.loggedIn) {
    return <Redirect to="/" />
  }

  return <Wizard {...props} />
}

export default connect(mapStateToProps)(ResetPasswordContainer)
