import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import lmClient from 'clients/lmClient'
import { CUSTOMER_DELETE, CUSTOMER_DELETE_ERROR, CUSTOMER_DELETE_SUCCESS } from './actionTypes'

export const customerDelete = (customerId: string) => async (
  dispatch: ThunkDispatch<any, null, Action<string>>
) => {
  dispatch({ type: CUSTOMER_DELETE })

  try {
    await lmClient().Customer.delete({ customerId })

    dispatch({ type: CUSTOMER_DELETE_SUCCESS, payload: { customerId } })
  } catch (error) {
    dispatch({ type: CUSTOMER_DELETE_ERROR, error: true, payload: error })
  }
}
