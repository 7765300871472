import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from 'components/Auth/Login'
import Register from '../Auth/Register'
import Users from './Users'
import Profile from './Profile'
import ChangePassword from '../Auth/ChangePassword'
import ResetPassword from '../Auth/ResetPassword'
import AuthHeader from './AuthHeader'
import Customers from './Customers'
import CustomerForm from './Customers/CustomerForm'
import { Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AdminApp = ({ loggedIn }) => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/login"></Route>
          <Route path="/register"></Route>
          <Route path="/resetPassword"></Route>
          {!loggedIn && <Redirect to="/login" />}
        </Switch>

        <AuthHeader />

        <Container>
          <Row>
            <Col>
              <Switch>
                <Route path="/register">
                  <Register />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route path="/changePassword">
                  <ChangePassword />
                </Route>
                <Route path="/resetPassword">
                  <ResetPassword />
                </Route>
                <Route path="/users">
                  <Users />
                </Route>
                <Route path="/customers/create">
                  <CustomerForm />
                </Route>
                <Route path="/customers/:customerId">
                  <CustomerForm />
                </Route>
                <Route path="/customers">
                  <Customers />
                </Route>
              </Switch>
            </Col>
          </Row>
        </Container>
      </Router>
      <ToastContainer />
    </>
  )
}

export default AdminApp
