import { connect } from 'react-redux'
import {
  customerCreate,
  customerUpdate,
  customerFetchOne,
} from 'store/customers/actions'
import { AppState } from 'store'
import {
  selectCustomersLoading,
  selectCustomersError,
  selectCustomers,
} from 'store/customers/reducer'
import CustomerForm from './CustomerForm'
import { selectAuthState } from 'store/auth/reducer'

const mapStateToProps = (state: AppState) => ({
  loading: selectCustomersLoading(state),
  error: selectCustomersError(state),
  customers: selectCustomers(state),
  auth: selectAuthState(state),
})

const mapDispatchToProps = { customerFetchOne, customerCreate, customerUpdate }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerForm)
