import React from 'react'
import { Field, ErrorMessage } from 'formik'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'

const FormField = ({ name, label, inputType, placeholder, disabled = false }) => (
  <Field name={name}>
    {({ field, formProps }) => (
      <FormGroup controlId={name}>
        <Row>
          <Col style={{ textAlign: 'right' }}>
            <FormLabel size="sm">{label}</FormLabel>
          </Col>
          <Col>
            <FormControl
              size="sm"
              type={inputType}
              placeholder={placeholder}
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
            />
            <ErrorMessage
              name={name}
              component={({ children }) => <Alert variant="danger">{children}</Alert>}
            />
          </Col>
        </Row>
      </FormGroup>
    )}
  </Field>
)

export default FormField
