import createClient from 'clients/authClient'

import { AUTH_LOGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_ERROR } from './actionTypes'

export const authLogin = (username, password) => async (dispatch, getState) => {
  dispatch({ type: AUTH_LOGIN })

  createClient(getState())
    .Auth.login({ body: { username, password } })
    .then(response => {
      const jwt = response.data().token
      dispatch({ type: AUTH_LOGIN_SUCCESS, payload: { jwt } })
    })
    .catch(response => {
      const error = response ? response.data() : 'network error'
      dispatch({ type: AUTH_LOGIN_ERROR, error: true, payload: error })
    })
}
