import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  fetchSchoologyUser,
  selectSchoologyUserError,
  selectSchoologyUserLoading,
  selectSchoologyUserId,
} from 'store/schoology/userInfo'
import Instructor from './Instructor'
import NeedSchoologyConnection from './NeedSchoologyConnection'
import { CircularProgress } from '@mui/material'

const mapStateToProps = (state) => ({
  schoologyUserError: selectSchoologyUserError(state),
  schoologyUserLoading: selectSchoologyUserLoading(state),
  schoologyUserId: selectSchoologyUserId(state),
})

const mapDispatchToProps = {
  fetchSchoologyUser,
}

const InstructorContainer = (props) => {
  useEffect(() => {
    props.fetchSchoologyUser()
  }, [])

  const { schoologyUserId, schoologyUserLoading, schoologyUserError } = props

  if (schoologyUserLoading) {
    return <CircularProgress />
  }

  if (!schoologyUserId) {
    return <NeedSchoologyConnection />
  }

  return <Instructor {...props} />
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructorContainer)
