import api from 'clients/lmClient'
import { useMutation } from 'react-query'

import downloadBlob from 'utils/downloadBlob'
import { decode } from 'base64-arraybuffer'

export type AssignmentRequestResponse = { jobId: string; filename: string }

export type JobStatusResponse = {
  id: string
  inputs: string
  status: 'created' | 'complete'
  result: any
  created_at: string
  modified_at?: string
}

export const useRequestMarkedAssignment = () =>
  useMutation(
    async (assignmentId: string) =>
      new Promise((resolve, reject) => {
        api()
          .Assignments.request({ assignmentId })
          .catch(reject)
          .then(async (response) => {
            const { jobId, filename } = response?.data() as AssignmentRequestResponse

            const intervalId = setInterval(async () => {
              const statusResponse = await api().Jobs.status({ jobId })
              const job = statusResponse.data() as JobStatusResponse

              if (job.status === 'complete') {
                clearInterval(intervalId)

                const downloadResponse = await api().Assignments.download({
                  assignmentId,
                  jobId,
                })

                if (!downloadResponse?.responseData) {
                  reject(new Error('no download'))
                }

                // @ts-ignore
                const blob = new Blob([decode(downloadResponse.responseData)], {
                  type: 'application/pdf',
                })

                downloadBlob(blob, filename)

                resolve('done')
              }
            }, 2000)
          })
      })
  )
