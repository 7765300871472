import lmClient from 'clients/lmClient'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { CUSTOMER_CREATE, CUSTOMER_CREATE_ERROR, CUSTOMER_CREATE_SUCCESS } from './actionTypes'
import { CustomerValues } from 'types'

export * from './fetchActions'
export * from './deleteActions'
export * from './updateActions'

export const customerCreate = (values: CustomerValues) => async (
  dispatch: ThunkDispatch<any, null, Action<string>>
) => {
  dispatch({ type: CUSTOMER_CREATE, payload: values })

  lmClient()
    .Customer.create({ body: values })
    .then(async response => {
      dispatch({ type: CUSTOMER_CREATE_SUCCESS, payload: { customer: response.data() } })
    })
    .catch(error => dispatch({ type: CUSTOMER_CREATE_ERROR, error: true, payload: error }))
}
