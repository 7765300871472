import React, { useEffect } from 'react'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SchoologyConnection = ({
  customerId = null,
  fetchSchoologyUser,
  loading,
  userId,
  disconnectSchoology,
}) => {
  const { t } = useTranslation('schoologyConnection')

  const connect = () => (window.location.href = '/api/v1/schoology/oauth_connect')

  useEffect(() => {
    console.log({ customerId })
    fetchSchoologyUser()
  }, [])

  if (loading) {
    return <CircularProgress />
  }

  if (userId) {
    return (
      <Stack spacing={1}>
        <Typography color="error" fontStyle="italic">
          {t('disconnectWarning')}
        </Typography>

        <Button variant="outlined" color="error" onClick={disconnectSchoology}>
          {t('disconnect')}
        </Button>
      </Stack>
    )
  }

  return (
    <Button variant="contained" onClick={connect}>
      {t('connect')}
    </Button>
  )
}

export default SchoologyConnection
