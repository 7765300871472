import {
  AUTH_REGISTER,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_CHANGE_PASSWORD,
  AUTH_CHANGE_PASSWORD_ERROR,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_LOGOUT,
} from './actionTypes'

const initialState = {
  jwt: null,
}

const reducer = function(state = initialState, action) {
  switch (action.type) {
    case AUTH_REGISTER:
      return { ...state, error: undefined, pendingAction: AUTH_REGISTER }
    case AUTH_LOGIN:
      return { ...state, error: undefined, pendingAction: AUTH_LOGIN }
    case AUTH_CHANGE_PASSWORD:
      return { ...state, error: undefined, pendingAction: AUTH_CHANGE_PASSWORD }

    case AUTH_REGISTER_ERROR:
    case AUTH_LOGIN_ERROR:
    case AUTH_CHANGE_PASSWORD_ERROR:
      return { ...state, error: action.payload, pendingAction: undefined }

    case AUTH_REGISTER_SUCCESS:
    case AUTH_LOGIN_SUCCESS:
      return { ...state, jwt: action.payload.jwt, error: undefined, pendingAction: undefined }

    case AUTH_CHANGE_PASSWORD_SUCCESS:
      return { ...state, error: undefined, pendingAction: undefined }

    case AUTH_LOGOUT:
      return { ...state, jwt: null, error: undefined }

    default:
      return state
  }
}

export default reducer

const selectBase = state => state.auth

export const selectAuthPending = state => selectBase(state).pendingAction
export const selectAuthError = state => selectBase(state).error
export const selectAuthJwt = state => selectBase(state).jwt
export const selectAuthLoggedIn = state => Boolean(selectBase(state).jwt)
export const selectAuthState = state => ({ auth: { jwt: selectAuthJwt(state) } })
