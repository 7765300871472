import React from 'react'
import PdfIcon from './pdf-icon.png'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Container, CircularProgress, Stack, Typography } from '@mui/material'

const DownloadLink = ({ filename, onClick, created_at, loading, hideDate }) => {
  const { t } = useTranslation('downloadLink')

  const formatter = dayjs(created_at)
  const date = formatter.format('ddd MMM D, YYYY')
  const time = formatter.format('h:mm a')

  return (
    <Container>
      {!hideDate && (
        <div style={{ fontSize: 12, color: 'rgb(103, 117, 131)' }}>
          {t('posted', { date, time })}
        </div>
      )}

      {loading ? (
        <Stack direction="row" spacing={2}>
          <CircularProgress size={14} />
          <Typography>{t('downloadRequested')}</Typography>
        </Stack>
      ) : (
        <>
          <img src={PdfIcon} alt={filename} />

          <a style={{ color: '#4479b3' }} href="#" onClick={onClick}>
            {filename}
          </a>
        </>
      )}
    </Container>
  )
}

export default DownloadLink
