import { useMutation } from 'react-query'
import api from 'clients/lmClient'

export const useDeleteAssignment = () =>
  useMutation(async (assignmentId: string) => {
    const response = await api().Assignments.delete({ assignmentId })

    const data = response.data()

    return data
  })
