import { connect } from 'react-redux'
import { authRegister } from 'store/auth/actions'
import { selectAuthError, selectAuthPending } from 'store/auth/reducer'
import Register from './Register'

const mapStateToProps = state => ({
  loading: selectAuthPending(state),
  error: selectAuthError(state),
})

const mapDispatchToProps = { authRegister }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register)
