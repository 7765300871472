import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import authReducer from './auth/reducer'
import adminReducer from './admin/reducer'
import schoologyReducer from './schoology/reducer'
import customersReducer from './customers/reducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import { logger, crashReporter } from './gaMiddlewares'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'auth',
  storage,
}

const combinedReducers = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  admin: adminReducer,
  schoology: schoologyReducer,
  customers: customersReducer,
})

export type AppState = ReturnType<typeof combinedReducers>

const middlewares = [logger, crashReporter, thunkMiddleware]
const middleWareEnhancer = applyMiddleware(...middlewares)

const store = createStore(combinedReducers, composeWithDevTools(middleWareEnhancer))

export const persistor = persistStore(store)

export default store
