import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import Spinner from 'react-bootstrap/Spinner'
import FormField from '../../../ui/FormField'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { Customer, CustomerValues, CustomerStatus } from 'types'
import { propEq } from 'ramda'
import lmClient from 'clients/lmClient'
import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const CustomerSchema = Yup.object().shape({
  contact_name: Yup.string()
    .min(3, 'Use at least 3 characters for Contact Name')
    .max(50, 'Use up to 50 characters for Contact Name')
    .required('Required'),

  contact_email: Yup.string()
    .email('Invalid email')
    .required('Required'),

  district_url: Yup.string()
    .url()
    .required('Required'),

  schoology_consumer_key: Yup.string()
    .min(3, 'Please enter your key as generated by schoology.')
    .max(50, 'Please enter your key as generated by schoology.')
    .nullable(),
  schoology_consumer_secret: Yup.string()
    .min(3, 'Please enter your secret as generated by schoology.')
    .max(50, 'Please enter your secret as generated by schoology.')
    .nullable(),

  district: Yup.string()
    .min(3, 'Use at least 3 characters for District')
    .max(50, 'Use up to 50 characters for District')
    .nullable(),
  city: Yup.string()
    .min(2, 'Use at least 2 characters for City')
    .max(50, 'Use up to 50 characters for City')
    .nullable(),
  state: Yup.string()
    .min(2, 'Use at least 3 characters for State')
    .max(50, 'Use up to 50 characters for State')
    .nullable(),
  phone: Yup.string()
    .matches(phoneRegExp)
    .nullable(),
})

type Props = {
  customers: Customer[]
  customerCreate(values: CustomerValues): void
  customerFetchOne(customerId: string): void
  customerUpdate(customerId: string, updates: Partial<Customer>): void
  loading: boolean
  error?: Error
  auth: any
}

type OwnProps = {
  customerId?: string
}

const CustomerForm = (
  { customers, customerFetchOne, customerCreate, customerUpdate, loading, error, auth }: Props,
  ownProps: OwnProps
) => {
  const { t } = useTranslation('customers')
  const { customerId } = useParams() as { customerId?: string }
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (customerId) {
      customerFetchOne(customerId)
    }
  }, [])

  const existingCustomer = customerId && customers.find(propEq('id', customerId))

  const testSchoologyKey = async (values: CustomerValues) => {
    const testKeyRequest = {
      consumer_key: values.schoology_consumer_key,
      consumer_secret: values.schoology_consumer_secret,
      schoology_site: values.district_url,
    }

    return lmClient(auth).Schoology.testKey({ body: testKeyRequest })
  }

  const onSubmit = async (values: CustomerValues) => {
    try {
      await testSchoologyKey(values)
    } catch (error) {
      return window.alert(t('enterValidKey'))
    }

    if (existingCustomer) {
      customerUpdate(existingCustomer.id, values)
    } else {
      customerCreate(values)
    }
    setSubmitted(true)
  }

  if (loading) {
    return <Spinner animation="border" role="status" size="sm" />
  }

  if (submitted && !error) {
    return <Redirect to="/customers" />
  }

  return (
    <div>
      <h2>{t('createCustomer')}</h2>

      {error && <Alert variant="danger">{JSON.stringify(error)}</Alert>}

      <Formik
        initialValues={{
          status: CustomerStatus.ENABLED,
          contact_name: '',
          contact_email: '',
          district: '',
          city: '',
          state: '',
          phone: '',
          lti_consumer_key: '',
          lti_shared_secret: '',
          district_url: '',
          schoology_consumer_key: '',
          schoology_consumer_secret: '',
          ...existingCustomer,
        }}
        validationSchema={CustomerSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => {
          const generateNewKeys = () => {
            if (!window.confirm(t('newKeysWarning', { name: values.contact_name }))) {
              return
            }

            lmClient(auth)
              .Customer.generateKeys({ customerId })
              .then(response => {
                // @ts-ignore
                const { lti_consumer_key, lti_shared_secret } = response.data()
                setFieldValue('lti_consumer_key', lti_consumer_key)
                setFieldValue('lti_shared_secret', lti_shared_secret)
              })
              .catch(error => window.alert(t('generateKeysError')))
          }

          return (
            <Form>
              <FormField
                name="contact_name"
                inputType="username"
                label={t('contactName')}
                placeholder=""
              />
              <FormField
                name="contact_email"
                inputType="email"
                label={t('contactEmail')}
                placeholder=""
              />
              <FormField name="district" inputType="text" label={t('district')} placeholder="" />
              <FormField name="city" inputType="text" label={t('city')} placeholder="" />
              <FormField name="state" inputType="text" label={t('state')} placeholder="" />
              <FormField name="phone" inputType="text" label={t('phone')} placeholder="" />

              {customerId && (
                <>
                  <Button onClick={generateNewKeys}>{t('generateNewKeys')}</Button>
                  <FormField
                    name="lti_consumer_key"
                    inputType="text"
                    label={t('ltiConsumerKey')}
                    placeholder=""
                    disabled
                  />
                  <FormField
                    name="lti_shared_secret"
                    inputType="text"
                    label={t('ltiSharedSecret')}
                    placeholder=""
                    disabled
                  />
                </>
              )}

              <h3>{t('schoologyConnection')}</h3>

              <FormField
                name="district_url"
                inputType="text"
                label="Schoology URL"
                placeholder="https://district123.schoology.com/"
              />

              <FormField
                name="schoology_consumer_key"
                inputType="text"
                label={t('schoologyConsumerKey')}
                placeholder=""
              />
              <FormField
                name="schoology_consumer_secret"
                inputType="text"
                label={t('schoologyConsumerSecret')}
                placeholder=""
              />

              <Button as="input" type="submit" value="Submit" disabled={loading} block />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CustomerForm
