import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import lmClient from 'clients/lmClient'
import {
  CUSTOMER_FETCH_ALL,
  CUSTOMER_FETCH_ALL_ERROR,
  CUSTOMER_FETCH_ALL_SUCCESS,
  CUSTOMER_FETCH_ONE,
  CUSTOMER_FETCH_ONE_ERROR,
  CUSTOMER_FETCH_ONE_SUCCESS,
} from './actionTypes'

export const customerFetchAll = () => async (
  dispatch: ThunkDispatch<any, null, Action<string>>
) => {
  dispatch({ type: CUSTOMER_FETCH_ALL })

  try {
    const response = await lmClient().Customer.list()

    dispatch({ type: CUSTOMER_FETCH_ALL_SUCCESS, payload: { customers: response.data() } })
  } catch (error) {
    dispatch({ type: CUSTOMER_FETCH_ALL_ERROR, error: true, payload: error })
  }
}
export const customerFetchOne = (customerId: string) => async (
  dispatch: ThunkDispatch<any, null, Action<string>>
) => {
  dispatch({ type: CUSTOMER_FETCH_ONE })

  try {
    const response = await lmClient().Customer.byId({ customerId })

    dispatch({ type: CUSTOMER_FETCH_ONE_SUCCESS, payload: { customer: response.data() } })
  } catch (error) {
    dispatch({ type: CUSTOMER_FETCH_ONE_ERROR, error: true, payload: error })
  }
}
