import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import FormField from '../../ui/FormField'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

const Register = ({ authRegister, error, loading }) => {
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(undefined)

  useEffect(() => {
    if (submitted && !loading && !error) {
      setSuccess(true)
    }
  }, [loading, error])

  if (success) {
    return <Alert variant="success">Your account has been created.</Alert>
  }

  return (
    <div>
      <h2>Register</h2>

      {error && <Alert variant="danger">{error.message}</Alert>}

      <Formik
        initialValues={{ email: '', username: '', password: '', password2: '' }}
        validate={values => {
          const errors = {}

          if (!values.email) {
            errors.email = 'Required'
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
          }
          if (!values.username) {
            errors.username = 'Required'
          } else if (!/^[A-Z0-9._%+-]+$/i.test(values.username)) {
            errors.username = 'Invalid username'
          }

          if (values.password !== values.password2) {
            errors.password2 = 'Passwords must match'
          }
          return errors
        }}
        onSubmit={values => {
          authRegister(values)
          setSubmitted(true)
        }}
      >
        {() => (
          <Form>
            <FormField name="email" inputType="email" label="Email" />
            <FormField name="username" inputType="username" label="Username" />

            <FormField name="password" inputType="password" label="Password" />
            <FormField name="password2" inputType="password" label="Repeat Password" />

            <Button as="input" type="submit" value="Submit" disabled={loading} block />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Register
