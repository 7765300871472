import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import resources from './en-US.json'

const anLetters = ['a', 'e', 'i', 'o', 'u']

const getIndefiniteArticle = (value, capitalize) => {
  let firstLetter = (value && value.substring(0, 1).toLowerCase()) || 'b'

  if (anLetters.find(l => firstLetter === l)) {
    return capitalize ? 'An' : 'an'
  } else {
    return capitalize ? 'A' : 'a'
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en-US',
    debug: process.env.JEST_WORKER_ID !== undefined ? false : true,
    appendNamespaceToCIMode: true,

    keySeparator: true,

    interpolation: {
      escapeValue: false, // react already safes from xss

      format: function(value, format, lng) {
        if (lng && lng !== 'en-US') return ''

        if (format === 'en-indefinite-article')
          return `${getIndefiniteArticle(value, false)} ${value}`

        if (format === 'en-indefinite-article-capitalized')
          return `${getIndefiniteArticle(value, true)} ${value}`

        return value
      },
    },
  })

export default i18n
