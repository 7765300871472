import React, { useState, useEffect } from 'react'
import { Customer } from 'types'
import { useTranslation } from 'react-i18next'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { NavLink } from 'react-router-dom'
import { PencilFill, TrashFill } from 'react-bootstrap-icons'
import CustomerDetail from './CustomerDetail'

type Props = {
  customerFetchAll(): void
  customerDelete(custoemrId: string): void
  error?: Error
  loading: boolean
  customers: Customer[]
}

const Customers = ({ customerFetchAll, customers, customerDelete, loading, error }: Props) => {
  const { t } = useTranslation('customers')
  const [customerToDelete, setCustomerToDelete] = useState<Customer>()
  const [customerDetail, setCustomerDetail] = useState<Customer>()

  const closeDeleteModal = () => setCustomerToDelete(undefined)
  const closeDetailModal = () => setCustomerDetail(undefined)

  useEffect(() => {
    customerFetchAll()
  }, [])

  const pressDelete = (customer: Customer) => {
    setCustomerToDelete(customer)
  }

  return (
    <>
      <NavLink to="/customers/create">{t('addNewCustomer')}</NavLink>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>{t('contactName')}</th>
            <th>{t('districtUrl')}</th>
            <th>{t('status')}</th>
            <th>{t('edit')}</th>
            <th>{t('delete')}</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer: Customer) => {
            const { id, status, contact_name, district_url } = customer
            return (
              <tr key={id}>
                <td>
                  <a href="#" onClick={() => setCustomerDetail(customer)}>
                    {contact_name}
                  </a>
                </td>
                <td>{district_url}</td>
                <td>{status}</td>
                <td>
                  <NavLink to={`/customers/${id}`}>
                    <PencilFill />
                  </NavLink>
                </td>
                <td>
                  <span onClick={() => pressDelete(customer)}>
                    <TrashFill />
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <Modal show={!!customerToDelete} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Customer?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {customerToDelete && (
            <>
              <h5>{customerToDelete.contact_name}</h5>
              <div>{customerToDelete.district_url}</div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            {t('cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (customerToDelete) {
                customerDelete(customerToDelete.id)
                closeDeleteModal()
              }
            }}
          >
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={!!customerDetail} onHide={closeDetailModal}>
        {customerDetail && (
          <CustomerDetail customer={customerDetail} onPressOk={closeDetailModal} />
        )}
      </Modal>
    </>
  )
}

export default Customers
