import api from 'clients/lmClient'
import ACTION_TYPE from './actionTypes'

export const fetchSchoologyUserError = error => ({
  type: ACTION_TYPE.USER_INFO_ERROR,
  error: true,
  payload: error,
})

export const fetchSchoologyUserSuccess = userInfo => ({
  type: ACTION_TYPE.USER_INFO_SUCCESS,
  payload: { userInfo },
})

export const fetchSchoologyUser = () => async dispatch => {
  dispatch({ type: ACTION_TYPE.USER_INFO_FETCH })

  try {
    const response = await api().Schoology.userInfo()
    dispatch(fetchSchoologyUserSuccess(response.data()))
  } catch (error) {
    dispatch(fetchSchoologyUserError(error))
  }
}

export const disconnectSchoologyError = error => ({
  type: ACTION_TYPE.DISCONNECT_ERROR,
  error: true,
  payload: error,
})

export const disconnectSchoologySuccess = () => ({
  type: ACTION_TYPE.DISCONNECT_SUCCESS,
})

export const disconnectSchoology = () => async dispatch => {
  dispatch({ type: ACTION_TYPE.DISCONNECT })

  try {
    await api().Schoology.disconnect()
    dispatch(disconnectSchoologySuccess())
  } catch (error) {
    dispatch(disconnectSchoologyError(error))
  }
}
