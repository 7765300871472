import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { adminFetchUsers, adminClearUsers } from 'store/admin/actions'
import { selectAdminError, selectAdminPending, selectAdminUsers } from 'store/admin/reducer'
import Users from './Users'

const mapStateToProps = (state) => ({
  loading: selectAdminPending(state),
  error: selectAdminError(state),
  users: selectAdminUsers(state),
})

const mapDispatchToProps = { adminFetchUsers, adminClearUsers }

const UsersContainer = ({ adminFetchUsers, loading, error, users }) => {
  useEffect(() => {
    adminFetchUsers()
    return () => adminClearUsers()
  }, [])

  return <Users loading={loading} error={error} users={users} />
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer)
