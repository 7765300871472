import createClient from 'clients/authClient'

import {
  AUTH_CHANGE_PASSWORD,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_ERROR,
} from './actionTypes'

export const authChangePassword = (oldPassword, newPassword) => async (dispatch, getState) => {
  dispatch({ type: AUTH_CHANGE_PASSWORD })

  createClient(getState())
    .Auth.changePassword({ body: { oldPassword, newPassword } })
    .then(response => {
      dispatch({ type: AUTH_CHANGE_PASSWORD_SUCCESS })
    })
    .catch(response => {
      const error = response ? response.data() : 'network error'
      dispatch({ type: AUTH_CHANGE_PASSWORD_ERROR, error: true, payload: error })
    })
}
