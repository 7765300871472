import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import FormField from '../../../ui/FormField'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import createClient from 'clients/authClient'

const EnterNewPassword = ({ state, onComplete }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <h2>Enter New Password</h2>

      {error && <Alert variant="danger">There was a problem resetting your password.</Alert>}

      <Formik
        initialValues={{ password: '' }}
        validate={values => {
          const errors = {}

          if (!values.password) {
            errors.password = 'Required'
          }

          return errors
        }}
        onSubmit={values => {
          setLoading(true)
          createClient()
            .Auth.resetPassword({ body: { ...state, ...values } })
            .then(() => onComplete())
            .catch(setError)
            .finally(() => setLoading(false))
        }}
      >
        {() => (
          <Form>
            <FormField name="password" inputType="password" label="New Password" />

            <Button as="input" type="submit" value="Submit" disabled={loading} block />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EnterNewPassword
