import React from 'react'
import { useTranslation } from 'react-i18next'

const Profile = ({}) => {
  const { t } = useTranslation('users')

  return <h3>{t('Profile')}</h3>
}

export default Profile
