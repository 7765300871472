function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob)

  // Create a new anchor element
  const a = document.createElement('a')

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url
  a.download = filename || 'download'

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = function() {
    setTimeout(() => {
      // Release the object URL
      URL.revokeObjectURL(url)

      // Remove the event listener from the anchor element
      this.removeEventListener('click', clickHandler)

      // Remove the anchor element from the DOM
      ;(this.remove && (this.remove(), 1)) || (this.parentNode && this.parentNode.removeChild(this))
    }, 150)
  }

  // Add the click event listener on the anchor element
  a.addEventListener('click', clickHandler, false)

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  a.click()

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a
}

export default downloadBlob
