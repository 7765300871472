import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import FormField from '../../ui/FormField'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

const ChangePassword = ({ authChangePassword, error, loading }) => {
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(undefined)

  useEffect(() => {
    if (submitted && !loading && !error) {
      setSuccess(true)
    }
  }, [loading, error, submitted])

  if (success) {
    return <Alert variant="success">Your password has been updated.</Alert>
  }

  return (
    <>
      <h2>Change Password</h2>

      {error && <Alert variant="danger">{error.message}</Alert>}

      <Formik
        initialValues={{ username: '', password: '' }}
        validate={(values) => {
          const errors = {}
          if (!values.oldPassword) {
            errors.oldPassword = 'Required'
          } else if (!values.newPassword) {
            errors.newPassword = 'Required'
          }
          return errors
        }}
        onSubmit={({ oldPassword, newPassword }) => {
          authChangePassword(oldPassword, newPassword)
          setSubmitted(true)
        }}
      >
        {() => (
          <Form>
            <FormField name="oldPassword" inputType="password" label="Old Password" />
            <FormField name="newPassword" inputType="password" label="New Password" />

            <Button as="input" type="submit" value="Submit" disabled={loading} block />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ChangePassword
