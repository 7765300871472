import React from 'react'
import { connect } from 'react-redux'
import { authLogin } from 'store/auth/actions'
import { selectAuthError, selectAuthPending, selectAuthLoggedIn } from 'store/auth/reducer'
import Login from './Login'
import { Redirect } from 'react-router-dom'

const mapStateToProps = state => ({
  loading: selectAuthPending(state),
  error: selectAuthError(state),
  loggedIn: selectAuthLoggedIn(state),
})

const mapDispatchToProps = { authLogin }

const LoginContainer = props => {
  if (props.loggedIn) {
    return <Redirect to="/" />
  }

  return <Login {...props} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer)
