import React from 'react'
import { Customer } from 'types'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import dayjs from 'dayjs'
import ClipboardPlusToClipboard from 'react-copy-to-clipboard'
import { ClipboardPlus } from 'react-bootstrap-icons'
import { toast } from 'react-toastify'

type Props = {
  customer: Customer
  onPressOk(): void
}

const styles = {
  externalToolRow: {
    backgroundColor: '#ccddff',
  },
}

const CustomerDetail = ({ customer, onPressOk }: Props) => {
  const { t } = useTranslation('customers')

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('customerDetail')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <th>{t('contactName')}</th>
              <td>{customer.contact_name}</td>
            </tr>
            <tr>
              <th>{t('createdDate')}</th>
              <td>{dayjs(customer.created_at).format('MM-DD-YYYY HH:mm')}</td>
            </tr>
            <tr>
              <th>{t('updatedDate')}</th>
              <td>{dayjs(customer.updated_at).format('MM-DD-YYYY HH:mm')}</td>
            </tr>
            <tr>
              <th>{t('contactEmail')}</th>
              <td>{customer.contact_email}</td>
            </tr>
            <tr>
              <th>{t('districtUrl')}</th>
              <td>{customer.district_url}</td>
            </tr>

            <tr>
              <th colSpan={3} style={styles.externalToolRow}>
                &nbsp;{t('schoologyExternalTool')}
              </th>
            </tr>
            <tr>
              <th style={styles.externalToolRow}>&nbsp;{t('ltiURL')}</th>
              <td style={styles.externalToolRow}>{`${process.env.REACT_APP_LM_BASE_URL}/lti`}</td>
              <td style={styles.externalToolRow}>
                <ClipboardPlusToClipboard
                  text={`${process.env.REACT_APP_LM_BASE_URL}/lti`}
                  onCopy={() => toast(t('copiedKey', { key: 'URL' }))}
                >
                  <ClipboardPlus />
                </ClipboardPlusToClipboard>
                &nbsp;
              </td>
            </tr>
            <tr>
              <th style={styles.externalToolRow}>&nbsp;{t('ltiConsumerKey')}</th>
              <td style={styles.externalToolRow}>{customer.lti_consumer_key}</td>
              <td style={styles.externalToolRow}>
                <ClipboardPlusToClipboard
                  text={customer.lti_consumer_key}
                  onCopy={() => toast(t('copiedKey', { key: 'consumer key' }))}
                >
                  <ClipboardPlus />
                </ClipboardPlusToClipboard>
                &nbsp;
              </td>
            </tr>
            <tr>
              <th style={styles.externalToolRow}>&nbsp;{t('ltiSharedSecret')}</th>
              <td style={styles.externalToolRow}>{customer.lti_shared_secret}</td>
              <td style={styles.externalToolRow}>
                <ClipboardPlusToClipboard
                  text={customer.lti_shared_secret}
                  onCopy={() => toast(t('copiedKey', { key: 'shared secret' }))}
                >
                  <ClipboardPlus />
                </ClipboardPlusToClipboard>
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onPressOk}>
          {t('ok')}
        </Button>
      </Modal.Footer>
    </>
  )
}

export default CustomerDetail
