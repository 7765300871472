import ACTION_TYPE from './actionTypes'

const initialState = {
  loading: null,
  error: null,
  userId: null,
}

const reducer = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.USER_INFO_FETCH:
      return {
        ...state,
        loading: true,
      }

    case ACTION_TYPE.USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ACTION_TYPE.USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userId: action.payload.userInfo.api_uid,
      }

    case ACTION_TYPE.DISCONNECT:
      return {
        ...state,
        loading: true,
        userId: undefined,
      }
    case ACTION_TYPE.DISCONNECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ACTION_TYPE.DISCONNECT_SUCCESS:
      return {
        ...state,
        loading: false,
        userId: null,
      }

    default:
      return state
  }
}

export default reducer

const selectBase = state => state.schoology.userInfo

export const selectSchoologyUserId = state => selectBase(state).userId
export const selectSchoologyUserError = state => selectBase(state).error
export const selectSchoologyUserLoading = state => selectBase(state).loading
