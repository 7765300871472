import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, CircularProgress, Stack } from '@mui/material'
import api from 'clients/lmClient'
import CheckResult from './CheckResult'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const CheckButton = ({
  title,
  date,
  id,
  uid,
  checkResult,
  onNewResult,
  unchangedFromDownload,
  download,

  checkReport,
}) => {
  const { t } = useTranslation('instructor')
  const [checking, setChecking] = useState(false)

  const checkSubmission = () => {
    setChecking(true)
    api()
      .Schoology.checkSubmission({ uid, id })
      .then((result) => {
        setChecking(false)
        onNewResult && onNewResult(result.data())
      })
      .catch((error) => {
        console.log(error)
        setChecking(false)
        alert(t('problem'))
      })
  }

  return checkReport ? (
    <CheckResult
      mark={uid}
      download={download}
      unchangedFromDownload={unchangedFromDownload}
      checkReport={checkReport}
    />
  ) : (
    <Button variant="contained" onClick={checkSubmission} disabled={checking}>
      {checking ? (
        <Stack direction="row">
          <CircularProgress
            size={12}
            sx={{
              color: '#fff',
            }}
            thickness={2}
          />
          <span className="sr-only">{t('checking')}</span>
        </Stack>
      ) : (
        <>{t('check')}</>
      )}
    </Button>
  )
}

export default CheckButton
